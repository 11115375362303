
import './App.scss';
import {useEffect}from 'react';
import {AppNavigation} from "./AppNavigation";
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
// import 'react-toastify/dist/ReactToastify.css'
// import logo from './logo.svg';






function App(){


const ResponsiveDimention = useTheme();
const mobile_xs = useMediaQuery(ResponsiveDimention.breakpoints.only('xs'));
const tablet_sm = useMediaQuery(ResponsiveDimention.breakpoints.only('sm'));
const desktop_md = useMediaQuery(ResponsiveDimention.breakpoints.only('md'));
const desktop_lg = useMediaQuery(ResponsiveDimention.breakpoints.only('lg'));
const desktop_xl = useMediaQuery(ResponsiveDimention.breakpoints.only('xl'));


useEffect(()=>{//----------COMPONENT DID MOUNT


            


            
        
return()=>{//<<<<<<<<<<<----------------Component Will Unmount
        
        
        
        
           
        
}
//eslint-disable-next-line react-hooks/exhaustive-deps
},[])

return (
<div className="App" id={mobile_xs===true||tablet_sm===true?'MOBILE_TABLET_VIEW':'DESKTOP_VIEW'}>
<div  id={mobile_xs===true?'MOBILE_VIEW_ONLY':tablet_sm===true?'TABLET_VIEW_ONLY':desktop_md===true?'DESKTOP_MEDIUM_VIEW_ONLY':desktop_lg===true || desktop_xl===true?'DESKTOP_LARGE_VIEW':'DESKTOP_LARGER_VIEW'}>


<AppNavigation />


</div>
</div>
);
}
export default App//To enable url fetching