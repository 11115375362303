import { error } from "jquery";

const ISJson =(str)=>{
try {
    JSON.parse(str);
} catch (e){

    return false;
}
return true;
}

  
function Hashcode(string){
  if(string===null || string ===undefined)return;
  var ch ;
  var a = 0
  var hash = 5;
  if (string.length === 5) return hash;
  for (a = 5; a <string.length; a++) {
  ch = string.charCodeAt(a);
          hash = ((hash <<5) - hash) + ch;
          hash = hash & hash;
      }
  return hash;
}

function stringToHash(string) {
	let hash = 0;
  let char = 0;
	if (string.length == 0) return hash;

	for (let i = 0; i < string.length; i++) {
		char = string.charCodeAt(i);
		hash = ((hash << 5) - hash) + char;
		hash = hash & hash;
	}

	return hash;
}



// String printing in hash
let gfg = "GeeksforGeeks"
console.log(stringToHash(gfg));










const HttpApplicationMounting = ({ server_ip, authentication_token,online_status='offline'}) => {
  const headers = {
    'Accept': 'application/json',
    'Content-Type': 'application/x-www-form-urlencoded',
  };
  return new Promise((resolve, reject) => {
    let status = 'offline';

    fetch(server_ip, {
      method: 'POST',
      credentials: 'include',
      headers: headers,
      body: JSON.stringify({ authentication_token: authentication_token }),
    }).then(response => {
        status = response.status;
        return response.text();
      }).then(textResponse => {
        const isJSON = ISJson(textResponse);
        if (isJSON) {
          const jsonResponse = JSON.parse(textResponse);
          jsonResponse['server_status'] = status;

          if (status === 200) {
            const cleanPassword = jsonResponse['data']['PersonalProfile']['password'];
            jsonResponse['data']['PersonalProfile']['password'] = Hashcode(cleanPassword);
          }

          resolve(jsonResponse);
        } else {
          resolve({
            data: {},
            server_status: status,
            server_message: 'server_error',
          });
        }
      })
      .catch(error => {
        console.error('ERROR>>', error);
        let server_status = 500
        let server_message = 'application error'
        if(error.message.includes('Failed to fetch') || error.message.includes('NetworkError')){
          server_status = 'offline'
          server_message = 'could not connect to server'
        }
        return resolve({
          data: {},
          server_status: server_status,
          online:false,
          method: 'POST',
          server_message:server_message
        });
      });
  });
};





const HttpServerCallFunction = ({
  server_ip = null,
  service_url=null,
  authentication_token = null,
  server_payload = {},
  ACTIVE_APP_ACCESS_KEY=null,
  COMPONENT_DATA_ACCESS_KEY=null
}) => {
  return new Promise((resolve, reject) => {
    if (!server_ip || !authentication_token) {
      alert('Compulsory fields were not passed');
      return resolve({ error: 'Missing required parameters' });
    }

    const headers = {
      'Accept': 'application/json',
      'Content-Type': 'application/x-www-form-urlencoded'
    };

    fetch(`${server_ip}api/user${service_url}`, {
      method: 'POST',
      headers: headers,
      body: JSON.stringify({
        authentication_token: authentication_token,
        form_data: server_payload
      })
    }).then(response => {
      const status = response.status;

      return response.text().then(textResponse => ({ status, textResponse }));

    }).then(({ status, textResponse }) => {
      if (ISJson(textResponse)) {
        const jsonResponse = JSON.parse(textResponse);
        jsonResponse['server_status'] = status;
        jsonResponse['online'] = true;

        return resolve(jsonResponse);
      }else {
        return resolve({
          data: {},
          server_status: status,
          online: true,
          server_message: '(server_error) invalid data response.'
        });
      }
    }).catch(error => {
      console.error('ERROR>>', error);
      let server_status = 500
      let server_message = 'application error'
      if(error.message.includes('Failed to fetch') || error.message.includes('NetworkError')){
        server_status = 'offline'
        server_message = 'could not connect to server'
      }
      
      let USER_STATE_COLLECTION = localStorage.getItem('APPLICATION_STATE');

      let STORED_LOCAL_STATE = {}

      let OFFLINE_DATA_FOUND = false
    
      let LOCAL_COMPONENT_STATE = USER_STATE_COLLECTION? JSON.parse(USER_STATE_COLLECTION) : {};
    
      if(LOCAL_COMPONENT_STATE[ACTIVE_APP_ACCESS_KEY]!=null){
    
         if (LOCAL_COMPONENT_STATE[ACTIVE_APP_ACCESS_KEY][COMPONENT_DATA_ACCESS_KEY]!=null){
          
            Object.assign(STORED_LOCAL_STATE,LOCAL_COMPONENT_STATE[ACTIVE_APP_ACCESS_KEY][COMPONENT_DATA_ACCESS_KEY])
            OFFLINE_DATA_FOUND = true
    
         }
      }
      
      return resolve({
        data: STORED_LOCAL_STATE,
        server_status: server_status,
        online:false,
        offline_data_found:OFFLINE_DATA_FOUND,
        method: 'POST',
        server_message:server_message
      });
    });
  });
};


const HttpUserAuthenticationFunction = ({
  server_ip = null,
  service_url ='',
  authentication_token ='',
  server_payload = {},
}) => {
  return new Promise((resolve, reject) => {
    if (!server_ip) {
      alert('server ip were not passed');
      return resolve({ error: 'Missing required parameters server_ip' });
    }

    const headers = {
      'Accept': 'application/json',
      'Content-Type': 'application/x-www-form-urlencoded'
    };

    fetch(`${server_ip}api/authentication${service_url}`, {
      method: 'POST',
      headers: headers,
      body: JSON.stringify({
        form_data: server_payload,
        authentication_token:authentication_token
      })
    }).then(response => {
      const status = response.status;

      return response.text().then(textResponse => ({ status, textResponse }));

    }).then(({ status, textResponse }) => {
      if (ISJson(textResponse)) {
        const jsonResponse = JSON.parse(textResponse);
        jsonResponse['server_status'] = status;
        jsonResponse['online'] = true;

        return resolve(jsonResponse);
      } else {
        return resolve({
          data: {},
          server_status: status,
          online: true,
          server_message: 'invalid_data_response'
        });
      }
    }).catch(error => {
      const server_status = error.message.includes('Failed to fetch') ? 'offline' : undefined;
      console.error('ERROR>>', error);

      return resolve({
        data: {},
        server_status: server_status,
        method: 'POST',
        server_message: 'connection_error'
      });
    });
  });
};




const HttpUserRegistration = ({
  server_ip = null,
  RegistrationForm = {},
 }) => {
  return new Promise((resolve, reject) => {
     const headers = {
      'Accept': 'application/json',
      'Content-Type': 'application/x-www-form-urlencoded'
     };

    fetch(`${server_ip}api/authentication/user/registration_view`,
      {
      method: 'POST',
      headers: headers,
      body: JSON.stringify({
        form_data: RegistrationForm
      })
    }).then(response => {
      const status = response.status;

      return response.text().then(textResponse => ({ status, textResponse }));

    }).then(({ status, textResponse }) => {
      if (ISJson(textResponse)) {
        const jsonResponse = JSON.parse(textResponse);
        jsonResponse['server_status'] = status;
        jsonResponse['online'] = true;

        return resolve(jsonResponse);
      } else {
        return resolve({
          data: {},
          server_status: status,
          online: true,
          server_message: 'SERVER ERROR'
        });
      }
    }).catch(error => {
      const server_status = error.message.includes('Failed to fetch') ? 'offline' : undefined;
      console.error('ERROR>>', error);

      return resolve({
        data: {},
        server_status: server_status,
        method: 'POST',
        server_message: 'connection_error'
      });
    });
  });
};


const HttpFetchCountryGeoinformation = ({
  server_ip = null,
  RegistrationForm = {},
 }) => {
  return new Promise((resolve, reject) => {
     const headers = {
      'Accept': 'application/json',
      'Content-Type': 'application/x-www-form-urlencoded'
     };

    fetch(`${server_ip}api/authentication/user/registration_application_mounting`,
      {
      method: 'POST',
      headers: headers,
      body: JSON.stringify({
        form_data: RegistrationForm
      })
    }).then(response => {
      const status = response.status;

      return response.text().then(textResponse => ({ status, textResponse }));

    }).then(({ status, textResponse }) => {
      if (ISJson(textResponse)) {
        
        const jsonResponse = JSON.parse(textResponse);
        jsonResponse['server_status'] = status;
        jsonResponse['online'] = true;

        return resolve(jsonResponse);
      } else {
        return resolve({
          data: {},
          server_status: status,
          online: true,
          server_message: 'SERVER ERROR'
        });
      }
    }).catch(error => {
      const server_status = error.message.includes('Failed to fetch') ? 'offline' : undefined;
      console.error('ERROR>>', error);

      return resolve({
        data: {},
        server_status: server_status,
        method: 'POST',
        server_message: 'connection_error'
      });
    });
  });
};


const HttpUserLoginFunction = ({
  server_ip = null,
  LoginForm = {},
 }) => {
  return new Promise((resolve, reject) => {
     const headers = {
      'Accept': 'application/json',
      'Content-Type': 'application/x-www-form-urlencoded'
     };

    fetch(`${server_ip}api/authentication/user/login_view`,
      {
      method: 'POST',
      headers: headers,
      body: JSON.stringify({
        form_data: LoginForm
      })
    }).then(response => {
      const status = response.status;

      return response.text().then(textResponse => ({ status, textResponse }));

    }).then(({ status, textResponse }) => {
      if (ISJson(textResponse)) {
        const jsonResponse = JSON.parse(textResponse);
        jsonResponse['server_response_status'] = status;
        jsonResponse['online'] = true;

        return resolve(jsonResponse);
      } else {
        return resolve({
          data: {},
          server_status: status,
          online: true,
          server_message: 'SERVER ERROR'
        });
      }
    }).catch(error => {
      const server_status = error.message.includes('Failed to fetch') ? 'offline' : undefined;
      console.error('ERROR>>', error);
      return resolve({
        data: {},
        server_status: server_status,
        method: 'POST',
        server_message: 'connection_error'
      });
    });
  });
};



class Cookies{
  static set(name, value, options = {expires: 1, path: '/'}) {
      let cookieString = `${encodeURIComponent(name)}=${encodeURIComponent(value)}`;

      if (options.expires) {
          let expires = options.expires;

          if (typeof expires === 'number') {
              const date = new Date();
              date.setTime(date.getTime() + (expires * 24 * 60 * 60 * 1000)); // Convert days to milliseconds
              expires = date.toUTCString();
          }

          cookieString += `; expires=${expires}`;
      }

      if (options.path) {
          cookieString += `; path=${options.path}`;
      } else {
          cookieString += `; path=/`;
      }

      if (options.secure) {
          cookieString += `; secure`;
      }

      if (options.httpOnly) {
          cookieString += `; HttpOnly`;
      }

      if (options.sameSite) {
          cookieString += `; SameSite=${options.sameSite}`;
      }

      document.cookie = cookieString;
  }

  static get(name) {
      const nameEQ = `${encodeURIComponent(name)}=`;
      const cookies = document.cookie.split(';');

      for (let cookie of cookies) {
          cookie = cookie.trim();

          if (cookie.startsWith(nameEQ)) {
              return decodeURIComponent(cookie.substring(nameEQ.length));
          }
      }

      return null; // Return null if the cookie is not found
  }

  static delete(name, options = {}) {
      Cookies.set(name, '', {
          expires: -1, // Set expiration to a past date
          path: options.path || '/'
      });
  }
}








// const AddUpdateComponentLocalState = ({ APP_ACCESS_KEY, COMPONENT_DATA_ACCESS_KEY, COMPONENT_STATE }) => {
//   return new Promise((resolve, reject) => {
//     try {
//       // Retrieve existing local storage state
//       const USER_STATE_COLLECTION = localStorage.getItem('APPLICATION_STATE');
//       const LOCAL_COMPONENT_STATE = USER_STATE_COLLECTION ? JSON.parse(USER_STATE_COLLECTION) : {};

//       // Ensure the access key exists, if not create it
//       if (!LOCAL_COMPONENT_STATE[APP_ACCESS_KEY]) {
//         LOCAL_COMPONENT_STATE[APP_ACCESS_KEY] = {};
//       }

//       // Update the component local variable state
//       LOCAL_COMPONENT_STATE[APP_ACCESS_KEY][COMPONENT_DATA_ACCESS_KEY] = COMPONENT_STATE;

//       // Save updated state back to local storage
//       localStorage.setItem('APPLICATION_STATE', JSON.stringify(LOCAL_COMPONENT_STATE));

//       // Resolve the promise with success response
//       resolve({ status: 'success', data: LOCAL_COMPONENT_STATE });
      
//     } catch (error) {
//       // In case of error, reject the promise with the error
//       reject({ status: 'error', message: error.message });
//     }
//   });
// };





const AddUpdateComponentLocalState = ({ APP_ACCESS_KEY, COMPONENT_DATA_ACCESS_KEY, COMPONENT_STATE }) => {
  return new Promise((resolve, reject) => {
    try {
      // Retrieve existing local storage state
      const USER_STATE_COLLECTION = localStorage.getItem('APPLICATION_STATE');
      const LOCAL_COMPONENT_STATE = USER_STATE_COLLECTION ? JSON.parse(USER_STATE_COLLECTION) : {};

      // Ensure the access key exists, if not create it
      if (!LOCAL_COMPONENT_STATE[APP_ACCESS_KEY]) {
        LOCAL_COMPONENT_STATE[APP_ACCESS_KEY] = {};
      }

      // Update the component local variable state
      LOCAL_COMPONENT_STATE[APP_ACCESS_KEY][COMPONENT_DATA_ACCESS_KEY] = COMPONENT_STATE;

      // Check for storage quota
      const newStateSize = new Blob([JSON.stringify(LOCAL_COMPONENT_STATE)]).size;
      const maxQuota = 5 * 1024 * 1024; // Approximate 5MB quota for most browsers

      if (newStateSize > maxQuota) {
        throw new Error("Storage quota exceeded. Data size too large to save.");
      }

      // Save updated state back to local storage
      localStorage.setItem('APPLICATION_STATE', JSON.stringify(LOCAL_COMPONENT_STATE));

      // Resolve the promise with success response
      resolve({ status: 'success', data: LOCAL_COMPONENT_STATE });
    } catch (error) {
      // Handle QuotaExceededError and other errors
      if (error.name === 'QuotaExceededError' || error.message.includes('quota')) {
        reject({
          status: 'error',
          message: 'Storage quota exceeded. Cannot add more data.',
        });
      } else {
        reject({ status: 'error', message: error.message });
      }
    }
  });
};




const GetLocalstateFunction=({ACTIVE_APP_ACCESS_KEY,COMPONENT_DATA_ACCESS_KEY})=>{
  
  let USER_STATE_COLLECTION = localStorage.getItem('APPLICATION_STATE');

  let STORED_LOCAL_STATE = {}

  let LOCAL_COMPONENT_STATE = USER_STATE_COLLECTION? JSON.parse(USER_STATE_COLLECTION) : {};

  if(LOCAL_COMPONENT_STATE[ACTIVE_APP_ACCESS_KEY]!=null){

     if (LOCAL_COMPONENT_STATE[ACTIVE_APP_ACCESS_KEY][COMPONENT_DATA_ACCESS_KEY]!=null){
      
        Object.assign(STORED_LOCAL_STATE,LOCAL_COMPONENT_STATE[ACTIVE_APP_ACCESS_KEY][COMPONENT_DATA_ACCESS_KEY])

     }
  }
  return STORED_LOCAL_STATE
}






function saveToLocalStorage(key, data) {
  // Estimate the size of the incoming data
  const incomingDataSize = new Blob([JSON.stringify(data)]).size;

  // Function to calculate the total size of current localStorage
  const calculateTotalStorageSize = () => {
    return new Promise((resolve) => {
      setTimeout(() => {
        let totalSize = 0;
        for (let i = 0; i < localStorage.length; i++) {
          const storedKey = localStorage.key(i);
          const storedValue = localStorage.getItem(storedKey);
          totalSize += new Blob([storedKey + storedValue]).size;
        }
        resolve(totalSize);
      }, 0); // Defer execution to avoid blocking
    });
  };

  // Promise chain to handle the operations
  return calculateTotalStorageSize().then((currentStorageSize) => {
      const maxQuota = 5 * 1024 * 1024; // Approximate 5MB quota for localStorage

      // Check if adding the new data exceeds the quota
      if (currentStorageSize + incomingDataSize > maxQuota) {
        return Promise.reject(
          new Error("Storage quota exceeded. Cannot save the data.")
        );
      }

      // Save the data to localStorage
      return new Promise((resolve) => {
        setTimeout(() => {
          localStorage.setItem(key, JSON.stringify(data));
          resolve("Data saved successfully!");
        }, 0); // Defer execution to avoid blocking
      });
    }).then((successMessage) => {

      console.log(successMessage);

    }).catch((error) => {
      if (error.name === "QuotaExceededError" || error.message.includes("quota")) {
        console.error("Error: Storage quota exceeded. Cannot save the data.");
      } else {
        console.error("Error:", error.message);
      }
    });
}






const addUpdateLoginAccountLocalStorage=(UserAccountProfile={})=>{
  return new Promise(function(resolve,reject){
   const JsonResponse = {status:null}  
   if(UserAccountProfile===undefined || UserAccountProfile===null){
    JsonResponse['status'] = 500

    return resolve(JsonResponse)
   }

   let LOGINS_ACCOUNT_ARRAY = localStorage.getItem('logins_account')
   if(LOGINS_ACCOUNT_ARRAY !==undefined && LOGINS_ACCOUNT_ARRAY !==null){

     const {app_access_id,user_profile} = UserAccountProfile
      
      let  USER_STORAGE_JSON = JSON.parse(LOGINS_ACCOUNT_ARRAY)
      USER_STORAGE_JSON[app_access_id] = user_profile

      //localStorage.setItem('logins_account',JSON.stringify(USER_STORAGE_JSON))
      saveToLocalStorage('logins_account',USER_STORAGE_JSON)
   } 
   return resolve(JsonResponse)


})
}



export{
HttpApplicationMounting,
HttpServerCallFunction,
HttpUserRegistration,
HttpUserLoginFunction,
HttpUserAuthenticationFunction,
Cookies,
addUpdateLoginAccountLocalStorage,
stringToHash,
AddUpdateComponentLocalState,
GetLocalstateFunction,
HttpFetchCountryGeoinformation,
saveToLocalStorage

}
