function ErrorFallbackComponent({Error,resetErrorFunction}){
return(
<div id='ErrorFallbackBoundary'>
   <h6>Something went wrong</h6>
   {/* <pre>{Error}</pre> */}
   <button onClick={resetErrorFunction}>Try again.</button>
</div>
);
}

export {ErrorFallbackComponent}